.main-ul {
  justify-content: space-evenly;
  flex-direction: row;
  flex-wrap: wrap;
  span:not(.active) {
    text-decoration: underline;
  }
}
.image-div {
  flex-flow: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-content: space-between;
}