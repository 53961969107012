@import '~@fullcalendar/core/main.css';
@import '~@fullcalendar/daygrid/main.css';
@import '~@fullcalendar/timegrid/main.css';

body {
  background-color: #555;
  font-family: "Times New Roman", Times, serif;
}
.lob-bg-light {
  background: #660000;
}
.nav-link {
  color: #FFF;
}
.nav-link:hover {
  color:#f9a857;
}
i.fa {
  font-size: 18px;
  position: relative;
  top: 2px;
}
.active .nav-link {
  color: #f9a857;
}

.color-gold {
  color: #f9a857;
}
.color-blue {
  color: #3d70a3;
}
.color-grey {
  color: #666;
}
.color-maroon {
  color: #660000;
}
.color-white {
  color: #FFF;
}
.color-dark {
  color: #333;
}
.cursor-pointer {
  cursor: pointer;
}
.items-img img {
  width: 75%;
  cursor: pointer;
}
.react-multi-carousel-track {
  height: 100%;
}

//.modal-dialog-centered {
//  .modal-content {
//    border-width: 0;
//  }
//  .close {
//    margin: auto;
//    padding: 0;
//  }
//  .img-modal-header {
//    position: absolute;
//    right: 0;
//    z-index: 2;
//    background: #fff;
//    width: 30px;
//    border-radius: 0;
//  }
//}
.modal-open {
  padding-right: 0!important;
}
.map-section {
  width: 95%;
  height: 500px;
}