#home .carousel-container{
  margin: auto;
  width: 169px;
}
#home video {
  object-fit: fill;
}
.home-links {
  li {
    display: inline-block;
    margin-right: 15px;
    cursor: pointer;
  }
  i {
    font-size: 5px;
    position: relative;
    /* bottom: 17px; */
    top: -3px;
    margin-right: 3px;
  }
}