@media (min-width:768px) and (max-device-width : 1024px) {
  body,
  .btn,
  .form-control {
    font-size: 10px;
  }
  .email-cc {
    width: 63%!important;
    height: 16px!important;
  }
  .fixed-top-section,
  .main-section {
    padding: 0!important;
  }
  .logo {
    width: 38px!important;
  }
  .navbar-brand {
    width: 380px!important;
  }
  .navbar-brand {
    margin-top: 15px!important;
    .brand-name {
      font-size: 25px!important;
      margin-top: 0!important;
      padding-top: 10px;
    }
    .bigger {
      font-size: 40px!important;
    }
    small {
      font-size: 12px!important;
    }
    span.sub-header {
      bottom: 0!important;
      font-size: 14px;
    }
  }
  .stupa {
    height: 95px!important;
  }
  .lob-carousel {
    height: 100px!important;
  }

  .logo-left,
  .logo-right {
    width: 50px!important;
  }
}
@media (max-width: 760px) {
  body,
  .btn,
  .form-control {
    font-size: 7px;
  }
  .fixed-top-section,
  .main-section {
    padding: 0!important;
  }
  .email-cc {
    width: 63%!important;
    height: 16px!important;
  }
  .horizontal-nav {
    padding-top: 0!important;
    padding-bottom: 0!important;
    .nav-link::after {
      content: none!important;
    }
  }
  // .sidebar {
  //   top: 135px!important;
  //   left: 0!important;
  // }
  // #main {
  //   padding-top: 135px!important;
  //   margin-left: 69px!important;
  //   margin-right: 0!important;
  //   min-height: calc(100vh - 42px)!important;
  // }
  #footer {
    margin: 0!important;
  }
  h5 {
    font-size: 12px;
  }
  h6 {
    font-size: 10px;
  }
  .lob-carousel {
    height: 60px!important;
  }
  i.fa.reg-fa {
    font-size: 8px!important;
    top: 1px!important;
  }
  .dot {
    font-size: 4px!important;
    bottom: 1px!important;
  }
  .logo {
    width: 35px!important;
  }
  .navbar-brand {
    margin-top: 0px!important;
    margin-right: 0;
    width: 180px!important;
    .brand-name {
      font-size: 10px!important;
      margin-top: 0!important;
    }
    .bigger {
      font-size: 18px!important;
    }
    small {
      font-size: 9px!important;
    }
  }
  .logo-footer {
    margin-top: 5px!important;
    line-height: 0.9rem;
  }
  .navbar-collapse {
    .nav-item {
      text-align: right;
    }
    .navbar-nav {
      margin-top: 10px;
    }
  }
  .mane {
    width: 50px;
  }
  .contact-form,
  .wholesale {
    input,
    textarea {
      width: 58% !important;
    }
    input {
      height: 15px;
    }
    .form-group {
      margin-bottom: 0!important;
      line-height: 1rem!important;
    }
    label {
      min-width: 38%!important;
    }
  }
  .navbar-expand .navbar-nav:last-child .nav-link {
    padding-right: .125rem;
  }
  .navbar {
    padding-top: 6px;
    padding-bottom: 5px;
  }
  .home-links i {
    font-size: 2px!important;
    top: -2px!important;
  }
  .logo-left,
  .logo-right {
    width: 23px!important;
  }
  .sub-header {
    font-size: 6px!important;
  }
  .stupa {
    height: 47px!important;
  }
}