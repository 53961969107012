
.sidebar li > a,
.sidebar li > a > i {
  color: #FFF;
}
.sidebar li {
  padding: 10px;
}
.sidebar li .active {
  color: #f9a857;
}
.slimScrollDiv {
  position: relative;
  overflow: hidden;
  width: auto;
}
.list {
  overflow: hidden;
  width: auto;
  list-style: none;
}
.sidebar li > a {
  border-bottom: 1px solid #f9a857;
}
.extra-links {
  a:hover,
  a.active {
    color: #f9a857;
  }
}