.contact-form label {
  min-width: 30%;
  text-align: right;
  padding-right: 10px;
}
.contact-form input,
.contact-form textarea {
  width: 65%;
  float: right;
}
.contact-form .form-group {
  line-height: 2rem;
}