.top-section a:hover{
  color: #FFF;
}
.top-section {
  border: 1px solid #F9a857;
  border-bottom-width: 0;
  border-top-width: 0;
}
.app-content {
  /*margin: 0 50px;*/
}
.fixed-top-section {
  //position: fixed;
  //width: 100%;
  //z-index: 1001;
  padding: 0 52px;
}
.sidebar {
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  height: 100%;
  // position: fixed;
  //top: 252px;
  // padding: 0 52px;
  // z-index: 10;
  color: #bdbdbd;
  border-right: 1px solid #F9a857;
  border-left: 1px solid #F9a857;
}
#main {
  min-height: calc(100vh - 53px);
  //padding-top: 252px;
  //margin-left: 228px;
  //margin-right: 51px;
  border-right: 1px solid #F9a857;
}
#footer {
  margin: 0 52px;
  border: 1px solid #F9a857;
}
.lob-carousel {
    width: 100%;
    height: 120px;
}
.top-carousel {
  border-left: 1px solid #F9a857;
  border-right: 1px solid #F9a857;
  border-bottom: 1px solid #F9a857;
}
.react-multi-carousel-item {
  border-right: 1px solid #DDD;
  div {
    height: 100%;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
.f-15 {
  font-size: 15px!important;
}
.lob-carousel-label a {
  color: #F9a857;
}
.lob-carousel-label {
  border-bottom: 2px solid #F9a857;
  border-left: 1px solid #F9a857;
}
.logo {
  width: 70px;
  vertical-align: top;
}

.sub-nav-item {
  flex-grow: 1;
  text-align: center;
}
.sub-nav-item.active {
  color: #660000;
  border-bottom: 2px solid;
}
.border-lob {
  border: 1px solid #f9a857;
}
.singing-bowl-header {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  label {
    text-decoration: underline;
  }
  img.sb {
    width: 90%;
  }
}
.bg-light-grey {
  background: #f2f2f2;
}
.email-cc {
  width: 78%;
  height: 28px;
}
.join-btn {
  padding: 2px 8px;
}
.mailing-list {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.logo-footer {
  background: #ffc107;
}
.navbar-brand {
  width: 545px;
}
.main-section {
  padding: 0 52px;
}
.stupa {
  height: 120px;
  position: absolute;
  z-index: 1;
}