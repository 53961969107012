.wholesale label {
    min-width: 30%;
    text-align: right;
    padding-right: 10px;
}
.wholesale input,
.wholesale textarea {
    width: 65%;
    float: right;
}
.wholesale .form-group {
    line-height: 2rem;
}