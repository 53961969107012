.horizontal-nav .nav-link::after {
  content: "|";
  padding-left: 15px;
  color: #f9a857;
}
.horizontal-nav {
  border: 1px solid #F9a857;
  border-top-width: 0;
}
.nav-item:last-child .nav-link::after {
  content: "";
  padding-left: 0;
}
.navbar-brand {
  .brand-name {
    font-size: 40px;
    line-height: 1.3rem;
    margin-top: 23px;
    span {
      letter-spacing: 1px;
    }
  }
  .bigger {
    font-size: 60px;
  }
  small {
    font-size: 20px;
  }
  span.sub-header {
    position: relative;
    bottom: 2px;
  }
  i {
    font-size: 6px;
    position: relative;
    bottom: 2px;
    top: unset;
    margin: 0 2px;
  }
}